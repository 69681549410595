@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,600,700&display=swap');

.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}
.trigger:hover {
    color: #1890ff;
}

#root > section > aside > div > div.logo {
    height: 32px;
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#root > section > aside > div > div.logo > img {
    width: 80%;
}
#root > section > aside > div > .logo {
    height: 32px;
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#root > section > aside > div > .logo > img {
    width: 80%;
}

.root_layout{
    height: 100vh
}


.ant-descriptions-item-content{
    background: #fff !important;
}


.ant-drawer-content-wrapper{
    transition: all 0.3s !important;
}

.editableComponenteH1{
    font-size: 2em;
}
.editableComponenteH2{
    font-size: 1.5em;
    font-weight: bold;
}
.break-word{ word-wrap:break-word }
.editableComponenteEditing100{width: 100%}
.editableComponent {
    text-decoration: underline;
    color: blue;
}

.pull-right{
    float: right;
}

.full-width{
    width: 100%;
}

.demo-editor{
    min-height: 500px;
    border-left: 0.1px solid #e3e3e3;
    border-bottom: 0.1px solid #e3e3e3;
    border-right: 0.1px solid #e3e3e3;
}
.login-form{

}
.login-form input{
    color: #9096B4;
    background-color: transparent;
}
.login-form input{
    color: #9096B4;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9096B4;
    border-radius: 0;
}
.login-form input::placeholder{
    color: #9096B4;
}
.login-form input::-ms-input-placeholder{
    color: #9096B4;
}
.login-form-button{
    background: #0F4DFF !important;
    border-radius: 30px !important;
    padding: 14px 90px !important;
    height: auto !important;
    font-weight: bold !important;
    font-size: 18px !important;
}
.login-form-button-outline{
    background: transparent !important;
    border-radius: 30px !important;
    border: 1px solid #9096B4 !important;
    padding: 14px 90px !important;
    height: auto !important;
    font-weight: bold !important;
    font-size: 18px !important;
    color: #9096B4 !important;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{

    background-color: #767c9c !important;
}
.user_select .ant-select-selection{
    background: transparent !important;
    border: none !important;
    border-bottom: 1px solid #9096B4 !important;
    color: #9096B4 !important;
    border-radius: 0 !important;
}
.user_select span{
    color: #9096B4 !important;
}
.user_discount_radio .ant-radio-group{
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}
.user_discount_radio .ant-radio-button-wrapper{
    height: auto !important;
    background: transparent ;
    border: 0px !important;
    opacity: .6;
}
.user_discount_radio .ant-radio-button-wrapper:hover{
    background: #0f1324 ;
    opacity: .8;

}
.user_discount_radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background: #151A2E !important;
    border: 1px solid #9096B4 !important;
    opacity: 1;

}
.user_discount_radio .ant-radio-button-wrapper:not(:first-child)::before{
    background-color: transparent !important;
}
.user_discount_radio label span{
    display: grid !important;
}
.user_discount_radio label img{
    justify-self: center !important;
}
.user_discount_radio .user_discount_radio_title{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 240%;
    color: #9096B4;
}

.user_post_form label{
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: 175.9% !important;
    color: #9096B4 !important;
}

.user_post_form input{
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: 175.9% !important;
    color: #9096B4 !important;

    background: transparent !important;
    border: 0px;
    border-bottom: 1px solid #9096B4 !important;
    box-shadow: none !important;
    border-radius: 0px !important;
}

.user_post_form .ant-switch{
    background-color: #9096B4 !important;
}

.user_post_form .ant-switch-checked{
    background-color: #0F4DFf !important;
}
.user_post_form .ant-btn-primary{
    height: auto !important;
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 175.9% !important;
    color: #FFFFFF !important;
    padding: 14px 90px !important;

    background: #0F4DFF !important;
    border-radius: 900px !important;
    border: 0px !important;
}


.user_post_form_sm label{
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: 175.9% !important;
    color: #9096B4 !important;
}

.user_post_form_sm input{
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: 175.9% !important;
    color: #9096B4 !important;

    background: transparent !important;
    border: 0px;
    border-bottom: 1px solid #9096B4 !important;
    box-shadow: none !important;
    border-radius: 0px !important;
}

.user_post_form_sm .ant-switch{
    background-color: #9096B4 !important;
}

.user_post_form_sm .ant-switch-checked{
    background-color: #0F4DFf !important;
}
.user_post_form_sm .ant-btn-primary{
    height: auto !important;
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 15px !important;
    line-height: 175.9% !important;
    color: #FFFFFF !important;
    padding: 2px 90px !important;

    background: #0F4DFF !important;
    border-radius: 900px !important;
    border: 0px !important;
}


    /* sharing */
    .resp-sharing-button__link,
    .resp-sharing-button__icon {
      display: inline-block
    }
    
    .resp-sharing-button__link {
      text-decoration: none;
      color: #fff;
      margin: 0.5em
    }
    
    .resp-sharing-button {
      border-radius: 5px;
      transition: 25ms ease-out;
      padding: 0em 0.45em;
      font-family: Helvetica Neue,Helvetica,Arial,sans-serif
    }
    
    .resp-sharing-button__icon svg {
      width: 1em;
      height: 1em;
      margin-right: 0.4em;
      vertical-align: top
    }
    
    .resp-sharing-button--small svg {
      margin: 0;
      vertical-align: middle
    }
    
    /* Non solid icons get a stroke */
    .resp-sharing-button__icon {
      stroke: #fff;
      fill: none
    }
    
    /* Solid icons get a fill */
    .resp-sharing-button__icon--solid,
    .resp-sharing-button__icon--solidcircle {
      fill: #fff;
      stroke: none
    }
    
    .resp-sharing-button--twitter {
      background-color: #55acee
    }
    
    .resp-sharing-button--twitter:hover {
      background-color: #2795e9
    }
    
    .resp-sharing-button--pinterest {
      background-color: #bd081c
    }
    
    .resp-sharing-button--pinterest:hover {
      background-color: #8c0615
    }
    
    .resp-sharing-button--facebook {
      background-color: #3b5998
    }
    
    .resp-sharing-button--facebook:hover {
      background-color: #2d4373
    }
    
    .resp-sharing-button--tumblr {
      background-color: #35465C
    }
    
    .resp-sharing-button--tumblr:hover {
      background-color: #222d3c
    }
    
    .resp-sharing-button--reddit {
      background-color: #5f99cf
    }
    
    .resp-sharing-button--reddit:hover {
      background-color: #3a80c1
    }
    
    .resp-sharing-button--google {
      background-color: #dd4b39
    }
    
    .resp-sharing-button--google:hover {
      background-color: #c23321
    }
    
    .resp-sharing-button--linkedin {
      background-color: #0077b5
    }
    
    .resp-sharing-button--linkedin:hover {
      background-color: #046293
    }
    
    .resp-sharing-button--email {
      background-color: #777
    }
    
    .resp-sharing-button--email:hover {
      background-color: #5e5e5e
    }
    
    .resp-sharing-button--xing {
      background-color: #1a7576
    }
    
    .resp-sharing-button--xing:hover {
      background-color: #114c4c
    }
    
    .resp-sharing-button--whatsapp {
      background-color: #25D366
    }
    
    .resp-sharing-button--whatsapp:hover {
      background-color: #1da851
    }
    
    .resp-sharing-button--hackernews {
    background-color: #FF6600
    }
    .resp-sharing-button--hackernews:hover, .resp-sharing-button--hackernews:focus {   background-color: #FB6200 }
    
    .resp-sharing-button--vk {
      background-color: #507299
    }
    
    .resp-sharing-button--vk:hover {
      background-color: #43648c
    }
    
    .resp-sharing-button--facebook {
      background-color: #3b5998;
      border-color: #3b5998;
    }
    
    .resp-sharing-button--facebook:hover,
    .resp-sharing-button--facebook:active {
      background-color: #2d4373;
      border-color: #2d4373;
    }
    
    .resp-sharing-button--twitter {
      background-color: #55acee;
      border-color: #55acee;
    }
    
    .resp-sharing-button--twitter:hover,
    .resp-sharing-button--twitter:active {
      background-color: #2795e9;
      border-color: #2795e9;
    }
    
    .resp-sharing-button--email {
      background-color: #777777;
      border-color: #777777;
    }
    
    .resp-sharing-button--email:hover,
    .resp-sharing-button--email:active {
      background-color: #5e5e5e;
      border-color: #5e5e5e;
    }
    
    .resp-sharing-button--linkedin {
      background-color: #0077b5;
      border-color: #0077b5;
    }
    
    .resp-sharing-button--linkedin:hover,
    .resp-sharing-button--linkedin:active {
      background-color: #046293;
      border-color: #046293;
    }
    
    .resp-sharing-button--whatsapp {
      background-color: #25D366;
      border-color: #25D366;
    }
    
    .resp-sharing-button--whatsapp:hover,
    .resp-sharing-button--whatsapp:active {
      background-color: #1DA851;
      border-color: #1DA851;
    }
    


/* *SOMA LOADING */
.soma-loading .ant-spin-nested-loading > div > .ant-spin{
  background: #151a2eb5 !important;
  color: #767c9c !important;
  border-radius: 9px !important;
}
.soma-loading .ant-spin-container .ant-spin-blur{
  border-radius: 9px;
}
.soma-loading .ant-spin-text{
  color: #767c9c !important;

  text-shadow: none;
  font-weight: 700;
}
.soma-loading .ant-spin-dot-item{
  background-color: #767c9c;
}
.soma-loading .ant-alert-with-description .ant-alert-message{
  color: #000;
}

.pointer{
    cursor: pointer !important;
}

.align-baseline{
    vertical-align: baseline !important;
}


.pagination {
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  flex: none;
  order: 0;
  align-self: flex-end;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  line-height: 1.25;
  background-color: transparent;
  color: #9096B4;
  border: none;

  
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
}
.justify-content-center{
  justify-content: center!important;
}
.pagination-margin{
  margin-top: 20px;
}